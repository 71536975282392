import {useRef,useState} from "react";
import './App.css';

function App() {
    const canvas = useRef();
    const filename = useRef();
    const [error, setError] = useState();
    const canvasWidthElement = useRef();
    const canvasHeightElement = useRef();

    let [canvasWidth, setCanvasWidth] = useState(1200);
    let [canvasHeight, setCanvasHeight] = useState(628);

    const updateCanvasWidth = () => {
        setCanvasWidth(Number(canvasWidthElement.current.value));
    }

    const updateCanvasHeight = () => {
        setCanvasHeight(Number(canvasHeightElement.current.value));
    }

    var saveData = (function () {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data, fileName) {
            var url = window.URL.createObjectURL(data);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        };
    }());

    const download = async () => {
        canvas.current.toBlob(blob => {
            saveData(blob, "thumbnail.jpg");
        }, "image/jpeg")
    }

    const wtp = (percent) => percent*canvasWidth;
    const htp = (percent) => percent*canvasHeight;

    const setImage = async e => {
        setError(null);
        e.preventDefault();
        try {
            // Test CORS etc
            await fetch(filename.current.value);

            const ctx = canvas.current.getContext('2d');
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);

            await new Promise((resolve, reject) => {
                let image = new Image();
                image.crossOrigin = "anonymous";
                image.src = filename.current.value;
                image.onload = () => {
                    let width = canvasWidth;
                    let height = width*image.height / image.width;
                    let top = -(height - canvasHeight) / 2;
                    ctx.drawImage(image, 0, top, width, height);
                    resolve();
                }
            });


            ctx.fillStyle = '#547762';
            ctx.globalAlpha = 0.85;
            ctx.fillRect(0, htp(.841), canvasWidth, htp(.166));

            await new Promise((resolve, reject) => {
                ctx.globalAlpha = 1;
                let logo = new Image();
                logo.crossOrigin = "anonymous";
                logo.src = "https://static2.privatmaklaren.se/images/object-thumbnails/logo.svg";
                logo.onload = () => {
                    let width = wtp(.30);
                    ctx.drawImage(logo, wtp(.025), htp(.884), width, width*logo.height / logo.width);
                    resolve();
                }
            });

            await new Promise((resolve, reject) => {
                let slogan = new Image();
                slogan.crossOrigin = "anonymous";
                slogan.src = "https://static2.privatmaklaren.se/images/object-thumbnails/slogan.png";
                slogan.onload = () => {
                    let width = wtp(0.17);
                    ctx.globalAlpha = 1;
                    ctx.drawImage(slogan, wtp(0.775), htp(0.0956), width, width*slogan.height / slogan.width);
                    resolve();
                }
            });
        } catch (error) {
            setError("The image could not be loaded. Invalid URL or the server not providing a CORS header?");
        }
    }

    return (
        <div className="App">
            <h2>Create Object Thumbnail</h2>
            <p className="error">{error}</p>
            <div>
                <label htmlFor="url">URL: </label>
                <form>
                    <input type="text" id="url" name="url" ref={filename} placeholder="Image URL"/>
                    <button type="submit" onClick={setImage}>Load</button>
                </form>
                <label htmlFor="canvasWidth">Width: </label>
                <input id="canvasWidth" ref={canvasWidthElement} type="input" onChange={updateCanvasWidth} value={canvasWidth}/>
                <label htmlFor="canvasHeight">Height: </label>
                <input id="canvasHeight" ref={canvasHeightElement} type="input" onChange={updateCanvasHeight} value={canvasHeight}/>
                <div>
                    <button type="button" onClick={download}>Download</button>
                </div>
            </div>
            <canvas ref={canvas} width={canvasWidth} height={canvasHeight}></canvas>
        </div>
    );
}

export default App;
